<template>
    <r-e-dialog title="盘点记录" :visible.sync="dialogVisible" top="10vh">
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getAssetsInventoryList" :columns="tableColumn"
                   :query="formSearch" :height="500" notAuto>
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.inventoryStatus===700103" @click="look(row)">详情</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="报修详情" :visible.sync="imgDialogVisible" top="10vh">
            <el-select v-model="formData.typeId" placeholder="请选择受理状态" clearable size="small">
                <el-option label="待受理报修单" :value="1"/>
                <el-option label="已受理报修单" :value="2"/>
            </el-select>
            <r-e-table class="bg-white" ref="tableRepairRef" :dataRequest="getRepairManagement" :columns="tableRepairColumn"
                       :query="formData" :height="500" notAuto>
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
        </r-e-dialog>
    </r-e-dialog>
</template>

<script>
import {assetsDetailsInventoryTableColumn, tableRepairColumn} from "@/views/property-management/assets-management/data";
import {getAssetsInventoryList} from "@/api/assets";
import {createFullImageUrl} from "@/components/Upload/index";
import {repairManagement} from "@/api/tenant-management"

export default {
    name: "layer-assets-details-inventory",
    data() {
        return {
            dialogVisible: false,
            tableColumn: assetsDetailsInventoryTableColumn,
            tableRepairColumn,
            formSearch: {
                assetDetailUuid: null,
            },
            imgDialogVisible: false,
            imgList: [],
            formData: {
                leasorUuid: null,
                typeId: 1,
            }
        }
    },
    components: {},
    methods: {
        openDialog(data) {
            let that = this;
            that.dialogVisible = true;
            const {uuid} = data;
            this.formSearch.assetDetailUuid = uuid;
            that.$nextTick(() => {that.handleSearch()});
        },

        getAssetsInventoryList(params) {
            return getAssetsInventoryList(params);
        },

        handleSearch(){
            this.$refs["tableRef"].getTableData(true);
        },

        createFullImageUrl(uuid) {
            return createFullImageUrl(uuid)
        },

        look(data) {
            this.imgDialogVisible = true;
            const {uuid} = data;
            this.formData.leasorUuid = uuid;

            this.$nextTick(() => {this.handleRepairSearch()});

        },

        getRepairManagement(params){
            return  repairManagement(params);
        },

        handleRepairSearch(){
            this.$refs["tableRepairRef"].getTableData(true);
        },
    },
}
</script>

<style scoped>

</style>
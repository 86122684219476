var offsetX = 0;
var offsetY = 0;
var width = 50;
var height = 30;
var rotate = 0;
var marginX = 2.0;
var marginY = 2.0;
var contentWidth = width - marginX * 2;
var qrCodeHeight = (height - 10) - marginY * 2;
var qrCodeWidth = qrCodeHeight;
var fontSize = 3.2;

//组合打印数据
export const combinationPrintData = {
    InitDrawingBoardParam: {
        width,
        height,
        rotate,
        path: "ZT001.ttf",
        verticalShift: 0,
        HorizontalShift: 0
    },
    elements: [
        {
            type: 'qrCode',
            json: {
                x: marginX + offsetX,
                y: marginY + offsetY,
                height: qrCodeHeight,
                width: qrCodeWidth,
                value: null,
                codeType: 31,
                rotate: 0
            }
        },
        {
            type: 'text',
            json: {
                x: marginX * 2 + qrCodeWidth + offsetX,
                y: marginY + offsetY,
                height: qrCodeHeight,
                width: contentWidth - qrCodeWidth - marginX,
                value: null,
                fontFamily: "宋体",
                rotate: 0,
                fontSize,
                textAlignHorizonral: 1,
                textAlignVertical: 1,
                letterSpacing: 0.0,
                lineSpacing: 1.0,
                lineMode: 6,
                fontStyle: [true, false, false, false],
            }
        },
    ]
};

export const fangjianming = {
    type: 'text',
    json: {
        x: marginX + offsetX,
        y: offsetY + qrCodeHeight - 1,
        height: height - qrCodeHeight,
        width: width - marginX * 2,
        value: "安吉公务员住宅小区2栋1单元2703",
        fontFamily: "宋体",
        rotate: 0,
        fontSize,
        textAlignHorizonral: 0,
        textAlignVertical: 1,
        letterSpacing: 0.0,
        lineSpacing: 1.0,
        lineMode: 6,
        fontStyle: [true, false, false, false],
    }
};

export default {combinationPrintData, fangjianming};

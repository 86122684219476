import {MessageSuccess} from "@custom/message";
//websocket
var g_websocket;


/* //响应数据 */
var ackJsonData;

/**SDK初始化状态 */
var initSdkStatus = false;
/**设备状态 */
var deviceStatus = false;
/**消息列表 */
var MessageList = {};

let timeoutTimer;
const TIMEOUT_DURATION = 10000;

/**通过websocket发送消息 */
function sendMsg(msg, callback) {
    console.log('sendMsg', msg.apiName);
    MessageList[msg.apiName] = callback;

    var data = JSON.stringify(msg);

    var tryTimes = 10;

    if (!g_websocket || g_websocket.readyState !== WebSocket.OPEN) {

        if (callback && typeof callback === 'function') {
            callback(new Error("打印服务未开启"))
        }

        return;
    }
    timeoutTimer = setTimeout(function () {
        // //超时关闭打印服务，进入重连机制
        // g_websocket.close();
        if (callback && typeof callback === 'function') {
            console.log('回调超时');
            callback(new Error("打印服务消息接收超时"))
        }
    }, TIMEOUT_DURATION);

    for (var i = 0; i < tryTimes; i++) {
        g_websocket.send(data);
        return;
    }
}

//1.1 初始化打印服务
function getInstance(onServiceConnected, onNotSupportedService, onServiceDisconnected, onPrinterConnectSuccess, onPrinterDisConnect) {
    //是否已连接
    let isConnected = false;
    //是否已重连
    let isReconnecting = false;
    //重连时间
    let reconnectTimer = null;

    const connect = () => {

        if ('WebSocket' in window) {
            g_websocket = new WebSocket('ws://127.0.0.1:37989');
            if ('binaryType' in WebSocket.prototype) {
                g_websocket.binaryType = 'arraybuffer';

            }

            if ('timeout' in WebSocket.prototype) {
                g_websocket.timeout = 5000;
            }

            g_websocket.proxy_read_timeout =

                g_websocket.addEventListener('open', (event) => {
                    isConnected = true;
                    isReconnecting = false;
                    console.log('WebSocket connected !');
                    clearInterval(reconnectTimer);
                    onServiceConnected();
                });


            g_websocket.addEventListener('error', (event) => {
                if (timeoutTimer != null) {
                    clearTimeout(timeoutTimer);
                }

                isConnected = false;
                ackJsonData = '';
                console.log('WebSocket error !', event);
                if (!isReconnecting) {
                    isReconnecting = true;
                    onServiceDisconnected();
                    reconnect();
                }

            });

            g_websocket.addEventListener('close', (event) => {
                if (timeoutTimer != null) {
                    clearTimeout(timeoutTimer);
                }
                isConnected = false;
                ackJsonData = '';
                console.log('WebSocket close !', event);
                if (!isReconnecting) {
                    isReconnecting = true;
                    onServiceDisconnected();
                    reconnect();
                }

            });

            g_websocket.addEventListener('message', (event) => {

                if (timeoutTimer != null) {
                    console.log('收到消息');
                    clearTimeout(timeoutTimer);
                }

                readCallback(event, () => {
                    onPrinterConnectSuccess();
                }, () => {
                    onPrinterDisConnect();
                });
            });

        } else {
            onNotSupportedService();
        }


    };

    const reconnect = () => {
        if (!isConnected && isReconnecting) {
            clearInterval(reconnectTimer);
            reconnectTimer = setInterval(connect, 3000);
        }
    };

    connect();
}

//1.1.4 读回调
function readCallback(event, onPrinterConnectSuccess, onPrinterDisConnect) {
    var callBackInfo = event.data;
    console.log('readCallback', callBackInfo);
    ackJsonData = callBackInfo;

    var callbackName;

    if (isJSON(ackJsonData)) {
        var arrParse = JSON.parse(ackJsonData);


        //接口回调
        if (MessageList[arrParse.apiName]) {
            MessageList[arrParse.apiName](null, arrParse);
        }

        //回调分发
        if (arrParse.apiName == 'printStatus') {
            if (arrParse['resultAck']['online'] == 'online') {
                onPrinterConnectSuccess();

            } else {
                onPrinterDisConnect();
            }
        } else {
            if (arrParse['resultAck']['callback'] != undefined) {
                callbackName = arrParse['resultAck']['callback']['name'];

                if (callbackName == 'onConnectSuccess') {
                    let printerName = arrParse['resultAck']['callback']['printerName'];
                    onConnectSuccess(printerName);
                } else if (callbackName == 'onDisConnect') {
                    let printerName = arrParse['resultAck']['callback']['printerName'];
                    onDisConnect(printerName);
                } else if (callbackName == 'onCoverStatusChange') {
                    var coverStatus = arrParse['resultAck']['callback']['coverStatus'];
                    onCoverStatusChange(coverStatus);
                } else if (callbackName == 'onElectricityChange') {
                    var powerLever = arrParse['resultAck']['callback']['powerLever'];
                    onElectricityChange(powerLever);
                } else if (callbackName == 'onPaperStatusChange') {
                    var paperStatus = arrParse['resultAck']['callback']['paperStatus'];
                    onPaperStatusChange(paperStatus);
                } else if (callbackName == 'onPrintPageCompleted') {
                    // eslint-disable-next-line no-undef
                    onPrintPageCompleted();
                } else if (callbackName == 'onPrintProgress') {
                    // eslint-disable-next-line no-undef
                    onPrintProgress();
                } else if (callbackName == 'onAbnormalResponse') {
                    // eslint-disable-next-line no-undef
                    onAbnormalResponse();
                } else {
                    console.log('unknow callback api!');
                }
            }
        }

        ackJsonData = '';
    }
}

//2.1 打印机连接成功回调onConnectSuccess
function onConnectSuccess(printerName) {
    console.log('打印机连接成功!');
    initSdkStatus = true;
    deviceStatus = true;
}

//2.2 打印机断开回调onDisConnect
function onDisConnect(printerName) {
    console.log('打印机断开！');
    initSdkStatus = false;
}

//2.3 打印机上盖变化回调onCoverStatusChange
function onCoverStatusChange(coverStatus) {
    console.log('打印机盒盖有变化！');
}

//2.4 打印机电量变化回调onElectricityChange()
function onElectricityChange(powerLever) {
    console.log('打印机电量有变化！');
}

//2.5 打印机纸张状态变化回调onPaperStatusChange
function onPaperStatusChange(paperStatus) {
    console.log('打印机纸张状态有变化！');
}

//3. 初始化SDK
function initSdk(json, callbackFunction) {
    var jsonObj = {
        apiName: 'initSdk',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}


//获取所有当前PC上连接的精臣打印机
//4. 获取打印机列表getAllPrinters()
function getAllPrinters(callbackFunction) {
    //刷新设备时，关闭设备
    //closePrinter();
    var jsonObj = {apiName: 'getAllPrinters'};

    sendMsg(jsonObj, callbackFunction);
}

//5.选择并打开需要使用的打印机名称，及端口号
function selectPrinter(printerName, port, callbackFunction) {
    var jsonObj = {
        apiName: 'selectPrinter',
        parameter: {printerName: printerName, port: port}
    };
    sendMsg(jsonObj, callbackFunction);
}

//6.1 创建画板
function InitDrawingBoard(json, callbackFunction) {
    var jsonObj = {
        apiName: 'InitDrawingBoard',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.2 绘制文本
function DrawLableText(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableText',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.3 绘制一维码
function DrawLableBarCode(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableBarCode',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.4 绘制二维码
function DrawLableQrCode(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableQrCode',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.5 绘制线条
function DrawLableLine(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableLine',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.6 绘制图形
function DrawLableGraph(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableGraph',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.7 绘制图像
function DrawLableImage(json, callbackFunction) {
    var jsonObj = {
        apiName: 'DrawLableImage',
        parameter: json
    };

    sendMsg(jsonObj, callbackFunction);
}

//6.8 生成预览图
function generateImagePreviewImage(displayScale, callbackFunction) {
    var jsonObj = {
        apiName: 'generateImagePreviewImage',
        displayScale: displayScale
    };

    sendMsg(jsonObj, callbackFunction);
}

/**
 * 7.开始打印任务
 * @param {number} printDensity 打印浓度
 * @param {number} printLabelType 纸张类型
 * @param {number} printMaterial 材质
 * @param {number} printMode 打印模式
 * @param {number} count 总打印张数
 * @param {*} callbackFunction 回调函数
 */
function startJob(printDensity, printLabelType, printMode, count, callbackFunction) {
    var jsonObj = {
        apiName: 'startJob',
        parameter: {
            printDensity: printDensity,
            printLabelType: printLabelType,
            printMode: printMode,
            count: count
        }
    };
    sendMsg(jsonObj, callbackFunction);
}

//8.提交打印任务commitJob
function commitJob(printData, printerImageProcessingInfo, callbackFunction) {
    var printDataJson = eval('(' + printData + ')');
    var printerImageProcessingInfoJson = eval('(' + printerImageProcessingInfo + ')');
    var jsonObj = {
        apiName: 'commitJob',
        parameter: {
            printData: printDataJson,
            printerImageProcessingInfo: printerImageProcessingInfoJson['printerImageProcessingInfo'],
        }
    };
    sendMsg(jsonObj, callbackFunction);
}

//9.结束打印任务endJob
function endJob(callbackFunction) {
    var jsonObj = {apiName: 'endJob'};
    sendMsg(jsonObj, callbackFunction);
}

//10.取消打印任务cancleJob
function cancleJob(callbackFunction) {
    var jsonObj = {apiName: 'stopPrint'};
    sendMsg(jsonObj, callbackFunction);
}

//.设置关机时间
//nType--1：15分钟，2:30分钟，3:60分钟，4：never
function setPrinterAutoShutDownTime(nType, callbackFunction) {
    var jsonObj = {
        apiName: 'setPrinterAutoShutDownTime',
        parameter: {nType: nType}
    };
    sendMsg(jsonObj, callbackFunction);
}

function getResult(tryTime, apiName, errInfo) {
    let tryTimes = tryTime;

    let result = {};
    while (tryTimes--) {
        if (!isJSON(ackJsonData)) continue;

        var arrParse = JSON.parse(ackJsonData);
        if (arrParse['apiName'] === apiName) {
            result = arrParse['resultAck'];
            break;
        }
    }

    if (tryTimes <= 0) {
        result['result'] = false;
        result['errorCode'] = 0x12;
        result['info'] = errInfo;
    }
    return result;
}

function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            //console.log('error：'+str+'!!!'+e);
            return false;
        }

    }

    console.log('It is not a string!');
}



// 封装打印标签

let isPrinterConnected = false;
let isPrinterOnline = true;

function jcPrinterInit() {
    let isConnected = false;
    let isSupported = true;
    getInstance(() => {
        isConnected = true;
        console.log('打印服务连接成功');
        init();
    }, () => {
        isSupported = false;
        console.log('当前浏览器不支持打印服务');
    }, () => {
        isConnected = false;
        console.log('打印服务连接断开');
    }, () => {
        isPrinterOnline = true;
        console.log('打印连接');
    }, () => {
        isPrinterOnline = false;
        console.log('打印连接断开');
    });
}

function init() {
    initSdk({"fontDir": "",}, (error, data) => {
        if (error) {
            console.log(error.message);
            return;
        }
        const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;

        if (errorCode === 0) {
            console.log('初始化成功');
            getPrinterAll();
        } else {
            console.log('初始化失败');
            console.log(info);
        }
    });
}

function getPrinterAll() {
    let allPrinters = null;
    getAllPrinters((error, data) => {
        if (error) {
            console.log(error.message);
            return;
        }

        try {
            const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
            if (errorCode === 0) {
                allPrinters = JSON.parse(info);
                const allPrintersName = Object.keys(allPrinters);
                const allPrintersValue = Object.values(allPrinters);
                if (allPrintersName.length === 0) {
                    console.log('无打印机在线');
                    return;
                }
                xSelectPrinter(allPrintersName[0], parseInt(allPrintersValue[0]));
            } else {
                console.log('无打印机在线');
            }
        } catch (err) {
            console.log(err);
        }
    });
}

function xSelectPrinter(printerName, port) {
    isPrinterConnected = false;
    selectPrinter(printerName, port, (error, data) => {
        if (error) {
            console.log('连接失败');
            console.log(error.message);
            return;
        }
        const {errorCode} = JSON.parse(JSON.stringify(data)).resultAck;
        if (errorCode === 0) {
            console.log('连接成功');
        } else {
            console.log('连接失败')
        }
    })
}

function batchPrintJob(that, list, isPreview) {
    if (list == null || list.length === 0) {
        return;
    }

    //打印总份数
    var printQuantity = 1;

    let density = 5;
    let labelType = 1;
    let printMode = 2;

    console.log('density:' + density);
    console.log('labelType:' + labelType);
    console.log('printMode:' + printMode);
    console.log('总打印份数:' + list.length * printQuantity);
    const count = list.length * printQuantity;
    startJob(density, labelType, printMode, count, (error, data) => {
        if (error) {
            that.loading.close();
            console.log(error.message);
            return;
        }
        const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
        if (errorCode !== 0) {
            that.loading.close();
            console.log(info);
            return;
        }
        // 提交打印任务
        printTag(that, list, 0, isPreview);
    });
}

function printTag(that, list, x, isPreview) {
    //设置画布尺寸
    InitDrawingBoard(list[x].InitDrawingBoardParam, (error, data) => {
        if (error) {
            console.log(error.message);
            return;
        }
        const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
        if (errorCode !== 0) {
            console.log(info);
            return;
        }
        // 元素控件绘制
        printItem(that, list, x, list[x].elements, 0, isPreview);
    });
}

function printItem(that, list, x, item, i, isPreview) {
    console.log(item, 'item');
    if (i < item.length) {
        switch (item[i].type) {
            case 'text':
                //绘制文本
                DrawLableText(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });
                break;
            case 'qrCode':
                //绘制二维码
                DrawLableQrCode(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });

                break;
            case 'barCode':
                //绘制一维码
                DrawLableBarCode(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });
                break;
            case 'line':
                //绘制线条
                DrawLableLine(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });
                break;
            case 'graph':
                //绘制边框
                DrawLableGraph(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });
                break;
            case 'image':
                //绘制边框
                DrawLableImage(item[i].json, function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    }
                    const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                    if (errorCode !== 0) {
                        console.log(info);
                        return;
                    }

                    i++;
                    printItem(that, list, x, item, i, isPreview);
                });
                break;
        }
    } else { //遍历完成，开始打印
        // let index = Object.assign(x);
        // var jsonObj = {
        // 		"printerImageProcessingInfo": {
        // 		"width": width,
        // 		"height": height,
        // 		"margin": [0,0,0,0],
        // 		"printQuantity":1,
        // 		"epc":"1234"
        // 	}
        // };

        console.log('是否预览' + isPreview);
        if (isPreview) {
            //B32和T8等300点机型倍率填12，其他机器填8，默认值8
            generateImagePreviewImage(8, (error, data) => {
                if (error) {
                    console.log(error.message);
                    return;
                }

                const {errorCode, info} = JSON.parse(JSON.stringify(data)).resultAck;
                if (errorCode !== 0) {
                    console.log(info);
                    return;
                }

                that.url = "data:image/jpeg;base64," + JSON.parse(info).ImageData;
            });
            return;
        }
        let jsonObj = {
            "printerImageProcessingInfo": {
                "printQuantity": 1,
            }
        };
        commitJob(null, JSON.stringify(jsonObj), function (error, data) {
            if (error) {
                console.log(error.message);
                return;
            }

            const {
                errorCode,
                info,
                printQuantity,
                onPrintPageCompleted
            } = JSON.parse(JSON.stringify(data)).resultAck;
            var resultInfo = "commitJob ok";
            //异常导致打印终止
            if (errorCode !== 0) {
                console.log(info);
                return;
            }


            //回调与传参定义相反，考虑接入较多客户暂不修改为一致
            //var jsonObj = {		"printerImageProcessingInfo": {"printQuantity":2,}}; 提交任务的打印份数
            //printQuantity 回调打印页数的进度（一次commitJob提交为1页，内容可以不一样）
            //onPrintPageCompleted 回调打印份数的进度（一个commit的内容打印多张，内容一样）

            //回调页码为数据总长度且回调打印份数数据等于当前页需要打印的份数数据时，结束打印任务
            if (printQuantity == list.length && onPrintPageCompleted == jsonObj.printerImageProcessingInfo.printQuantity) {
                //结束打印任务
                endJob(function (error, data) {
                    if (error) {
                        console.log(error.message);
                        return;
                    } else {
                        var arrParse = JSON.parse(JSON.stringify(data));
                        if (String(arrParse.resultAck.info).indexOf("endJob ok") > -1) {
                            that.loading.close();
                            MessageSuccess('打印完成');
                        }
                    }

                });
                return;
            }

            //当前页数据提交完成，但是未完所有页数据提交，继续发送下一页数据
            if (String(info).indexOf(resultInfo) > -1 && x < list.length - 1) {
                //数据提交成功，数据下标+1
                console.log("发送下一页打印数据： ")
                x++;
                printTag(that, list, x, isPreview);
            }
        });
    }
}

export default {
    jcPrinterInit,
    batchPrintJob,
};